<template>
  <div class="collection">
    <div>
      <!-- <van-button type="primary" @click="setGoTo">主要按钮</van-button> -->
      <van-nav-bar title="收藏" :right-text="rightText" left-arrow @click-right="update" @click-left="onClickLeft" />
    </div>
    <div class="coll_continer_cell">
      <van-dropdown-menu style="margin-left:8px" active-color="#1989fa" >
        <van-dropdown-item v-model="activeValue" @change="dropChange" :options="option1" />
      </van-dropdown-menu>
      <div style="margin-right:16px"></div>
    </div>
    
    <div class="van_tab_panel">
      <div
        class="wrong_question-continer"
        v-if="list && list.length > 0"
      >
      
        <div v-for="(item,index) in list">
          <div class="chapter_card">
            <div class="chapter_cirle-box">
              <div class="chapter_box_title">
                <!-- <van-radio-group v-model="item.subjectId">
                  <van-radio  :name="item.subjectId" >{{ item.subjectTitle }}</van-radio>
                </van-radio-group> -->
                <van-checkbox v-if="rightText == '取消'" ref="checkboxes" @change="checkboxChange(item)" v-model="item.checked" @click="radioClick">{{ item.subjectTitle }}</van-checkbox>
                <span v-if="rightText == '编辑'">{{ item.subjectTitle }}</span>
              </div>
              <div v-if="rightText == '编辑'" class="chap_chongzuo" @click="setAnalysis(item)">解析</div>
            </div>
            <div class="line"></div>
          </div>

        </div>
  
        <div class="delete_continer" v-if="rightText == '取消'">
            <div class="delete_all"><van-checkbox style="margin-left:20px" @change="allChange" v-model="allChecked" @click="allClick">全选</van-checkbox></div>
            <div class="delete_btn">
              <div class="bottom_btn" @click="deleteClick">删除</div>
            </div>
        </div>

      </div>
      <div v-else>
        <van-empty description="暂无数据"></van-empty>
      </div>
    </div>
  </div>
</template>

<script>
import {
  chapterList,
  subjectList,
  paperList,
  paperSubjectList,
  collect_list,
  deleted_collect,
  subject_analysis
} from "@/api/questionbook.js";

export default {
  data() {
    return {
      allChecked:false,
      activeValue:1,
      rightText:"编辑",
      option1: [
        { text: '单选题', value: 1 },
        { text: '多选题', value: 2 },
        { text: '判断题', value: 3 },
        { text: '不定项选择题', value: 4 },
        { text: '简答题', value: 7 },
        { text: '综合题', value: 6 },
      ],
      list: [],
      paperlist: [],
      loading: false,
      finished: true,
      subMitJson:[],
    };
  },
  name: "collection",
  created() {
    this.init();
  },
  methods: {
    allClick(){

    },
    setAnalysis(item){
      console.log("items",item);
      this.$router.push({
        path:"/analysis",
        query:{
          id:item.subjectId,
          classify:item.classify
        }
      })
    },
    radioClick(item){
      
    },
    deleteClick(){
      if(this.subMitJson.length > 0){
        let result = this.subMitJson.join(",");
        deleted_collect(result).then((res)=>{
          if(res.data.code == 0){
            this.$toast('删除成功');
            this.init();
          }

        })

      }
      

    },
    dropChange(value){
      this.rightText = "编辑";
      collect_list(value, 0, 10).then((res) => {
        if (res.data.code == 0) {
          const datas = res.data.data;
          const newAry = [];
          datas.map((item)=>{
            item.checked = false;
            newAry.push(item);
          })
          this.list = newAry;
        } else {
          this.list = [];
        }
      });
    },
    update(){
      this.rightText == "编辑" ? this.rightText = "取消" : this.rightText = "编辑";


    },
    allChange(value){
      if(value){
        this.subMitJson = [];
        this.list.map((itemNew)=>{
          this.subMitJson.push(itemNew.subjectId);
        });
        this.$refs.checkboxes.map(item=>{
          return item.checked=true
        })
      }else{
        this.subMitJson = [];
        this.$refs.checkboxes.map(item=>{
          return item.checked=false
        })
      }

    },
    checkboxChange(item){
      console.log(item);
      if(item.checked){
        this.subMitJson.push(item.subjectId);
      }else{
        this.subMitJson.map((itemNew,index)=>{
          if(itemNew==item.subjectId){
            this.subMitJson.splice(index,1)
          }
        })
      }
    },
    init() {
      collect_list(this.activeValue, 0, 10).then((res) => {
        if (res.data.code == 0) {
          const datas = res.data.data;
          const newAry = [];
          datas.map((item)=>{
            item.checked = false;
            newAry.push(item);
          })
          this.list = newAry;
        } else {
          this.list = [];
        }
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    setCirleNum(item) {
      let num = (item.completeNumber / item.countNumber) * 100 + "%";
      return {
        width: num,
      };
    },
  },

  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="less" scoped>
.bottom_btn{
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: #5F7DFF;
  border-radius: 5px;
  width: 90%;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  margin-left: 5%;
}
.collection {
  width: 100%;
  height: 100%;
  background-color: white;
  /deep/ .van-nav-bar .van-icon {
    color: #000000;
  }
  /deep/ .van-tabs__line {
    background-color: #5f7dff;
  }
  /deep/ .van-nav-bar__text{
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #5F7DFF;
  }
  /deep/ .van-dropdown-menu__bar{
    box-shadow:none
  }
  .van_tab_panel {
    width: 100%;
    height: calc(100vh - 95px);
    overflow: hidden;
    overflow-y: auto;
  }
  .wrong_question-continer {
    //    overflow: hidden;
    //    overflow-y: auto;
  }
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.chapter_card {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0px 16px;
}
.chapter_box_title {
  width: 100%;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-right: 20px;
}

.chapter_cirle-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 12px;
}
.line {
  background-color: #dddddd;
  width: 100%;
  height: 1px;
}
.chapter_cirle-logo {
  width: 8px;
  height: 13px;
}
.coll_continer_cell{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.question_cirle {
  width: calc(100% - 75px);
  height: 20px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  .question_cirle-continer {
    width: calc(100% - 63px);
    height: 10px;
    background: #e7ecff;
    border-radius: 5px;
  }
  .question_cirle-yes {
    width: 50%;
    height: 10px;
    background: #5f7dff;
    border-radius: 5px;
  }
}
.question_num {
  width: 43px;
  height: 15px;
  font-size: 11px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 15px;
}

.chapter_left_icon_continer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chap_chongzuo {
  width: 62px;
  height: 28px;
  background: #5d7dff;
  border-radius: 14px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 28px;
}
.delete_continer{
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 60px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}
.delete_all{
  flex: 0.4;
}
.delete_btn{
  flex: 0.6;
  
}
</style>
